<template>
    <div class="d-flex">
            <span v-if="is_bool">{{skills.item.status === 1 ? 'Approved':'Not Approved'}}</span>
            <div v-if="!is_bool">
                <b-form-select v-model="selected_status" @change="changeStatus(skills,selected_status)"
                               class="mb-3">
                    <b-form-select-option :value="getStatus.id" v-for="(getStatus,index) in allStatus" :key="index" >{{getStatus.val}}
                    </b-form-select-option>
                </b-form-select>
            </div>
        </div>
</template>

<script>
    import {employeeMethods} from "@/state/helpers";
    export default {
        data(){
            return{
                msg : '',
                is_bool : false,
                selected_status:null,
                allStatus: [{id:1,val:'Approved'},{id:0,val:'Not Approved'}],
            }
        },
        props:{
            skills:{
                type:Object
            }
        },
        methods:{
            ...employeeMethods,
            changeStatus(skills, status) {
                let data = {id: skills.item.id, status: status}
                this.updateSkillsStatus({data}).then(res => {
                    this.checkingStatus(res.data.status);
                    this.msg = "Status changed successfully"
                    this.$swal({
                        icon: 'success',
                        text: this.msg,
                        toast: true,
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000
                    });
                }).catch(err => {
                    this.unauthorizedError(err);
                })
            },
            unauthorizedError(err) {
                if (err.data.status === 401) {
                    return this.$router.push('/logout');
                }
            },
            checkingStatus(status){
                this.is_bool = status === 1;
            },
        },
        mounted() {
            this.checkingStatus(this.skills.item.status);
            this.selected_status = this.skills.item.status
        },
    }
</script>