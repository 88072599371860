<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <loading v-if="loader"/>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">All Employees</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="employees"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(is_docs)="employee">
                                    <span :class="'text-white '+checkDocsClass(employee.item.is_docs)"
                                          style="height: 30px;margin: 5px">{{checkDocs(employee.item)}}</span>
                                </template>
                                <template v-slot:cell(docs)="employee">
                                    {{employee.item.documents.length}}
                                </template>
                                <template v-slot:cell(skills)="employee">
                                    <b-button class="btn btn-outline-info btn-sm"
                                              v-b-modal="employee.item.skills.length >0 ? 'employeeSkillsModal':''"
                                              @click="employeeSkills(employee.item)">Skills
                                    </b-button>&nbsp;
                                </template>
                                <template v-slot:cell(documents)="employee">
                                    <b-button class="btn btn-outline-info btn-sm"
                                              v-b-modal="employee.item.documents.length >0 ? 'employeeDocsModal':''"
                                              @click="employeeDocs(employee.item)">Documents
                                    </b-button>
                                </template>
                                <template v-slot:cell(status)="employee">
                                    <button :class="employee.item.status === 'Block' ? btn_color1:btn_color2"
                                            @click="toggleState(employee)">
                                        <div v-if="employee.item.status === 'Block'">Block</div>
                                        <div v-else>Unblock</div>
                                    </button>

                                </template>
                                <template v-slot:cell(action)="employee">
                                    <button class="btn btn-danger btn-sm" @click="deleteEmployee(employee.item.id)"><i
                                            class="fas fa-trash-alt"></i>
                                    </button>&nbsp;&nbsp;

                                </template>
                            </b-table>
                        </div>
                        <docs v-if="docModal" :user="selected_user"></docs>
                        <skills v-if="skillsModal" :user="selected_user"></skills>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <pagination :data="pagination" @pagination-change-page="getEmployees"
                                                    :limit="limit"></pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import loading from "../animation/loading";
    import {employeeMethods} from "@/state/helpers";
    import appConfig from "@/app.config";
    import pagination from "laravel-vue-pagination";
    import docs from "./docs";
    import skills from "./skills";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "Employees",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {Layout, PageHeader, docs, skills, loading, pagination},
        data() {
            return {
                docModal: false,
                skillsModal: false,
                selected_user: {},
                loader: false,
                limit: 5,
                title: "Employees",
                items: [
                    {
                        text: "Any",
                        href: "/"
                    },
                    {
                        text: "Employees",
                        href: "/"
                    },
                ],
                btn_color1: 'btn btn-success btn-sm',
                btn_color2: 'btn btn-danger btn-sm',
                api_status: 0,
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: true,
                fields:
                    [
                        {key: "name", label: 'Name', sortable: true},
                        {key: "email", label: 'Email', sortable: true},
                        {key: "mobile_number", label: 'Phone', sortable: true},
                        {key: "is_docs", label: 'Status', sortable: true},
                        {key: "docs", label: 'Uploaded Documents', sortable: false},
                        {key: "documents", label: 'Documents', sortable: false},
                        {key: "skills", label: 'Skills', sortable: false},
                        {key: "status", label: 'Action', sortable: false},
                        {key: "action", label: 'Action', sortable: false},
                    ]
            };
        },
        watch: {
            currentPage: {
                handler: function () {
                }
            }
        },
        computed: {
            rows() {
                return this.$store.state.employee.employees.length;
            },
            employees() {
                return this.$store.state.employee.employees
            },
            pagination() {
                return this.$store.state.employee.pagination
            }
        },
        methods: {
            ...employeeMethods,

            getEmployees(page = 1) {
                this.loader = true;
                this.skillsModal = false
                this.getAllEmployees({page: page}).then(res => {
                    if (res.status === 200) {
                        this.loader = false;
                    }
                }).catch(error => {
                    console.log("error", error.data.errors);
                    this.$swal({
                        icon: 'error',
                        toast: true,
                        text: "Session Expired! Please login again.",
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    this.loader = false;
                    console.log("error", error);
                    this.unauthorizedError(error)
                });
            },
            unauthorizedError(err) {
                if (err.data.status === 401) {
                    return this.$router.push('/logout');
                }
                if (err.data.status === 400) {
                    this.errorMessage(err.data.message)
                }
                if (err.data.status === 500) {
                    this.errorMessage('Failed! error')
                }
            },
            errorMessage(message) {
                this.$swal({
                    icon: 'error',
                    text: message,
                    showCloseButton: true,
                    showConfirmButton: false,
                    timer: 3000
                });
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            toggleState(employee) {
                if (employee.item.status === "Block") {
                    this.api_status = 24;
                } else {
                    this.api_status = 23
                }
                this.loader = true;
                this.toggleStatus({id: employee.item.id, status: this.api_status}).then(res => {
                    if (res.status == 200) {
                        this.loader = false;
                        this.msg = "Status Updated successfully"
                        this.$swal({
                            icon: 'success',
                            toast: true,
                            text: this.msg,
                            showCloseButton: false,
                            showConfirmButton: false,
                            timer: 3000
                        })
                        if (this.api_status === 24) {
                            employee.item.status = "Unblock"
                        } else if (this.api_status === 23) {
                            employee.item.status = "Block"
                        }
                    }
                }).catch(error => {
                    this.loader = false;
                    console.log(error)
                    this.$swal({
                        icon: 'error',
                        toast: true,
                        text: "Failed to save! Try again",
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    console.log("error", error);
                    this.unauthorizedError(error)
                });
            },
            employeeDocs(employee) {
                if (employee.documents.length === 0) {
                    this.$swal({
                        icon: 'error',
                        toast: true,
                        position: 'top-center',
                        text: ' There is no documents',
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    return
                }
                this.docModal = true
                this.selected_user = employee
            },
            employeeSkills(employee) {
                console.log("employee", employee)
                if (employee.skills.length === 0) {
                    this.$swal({
                        icon: 'error',
                        toast: true,
                        position: 'top-center',
                        text: ' There is no skills',
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    return
                }
                this.skillsModal = true
                this.selected_user = employee
            },
            deleteEmployee(id) {
                this.$swal({
                    icon: 'warning',
                    text: 'Are you sure to delete',
                    showCancelButton: true,
                    toast: true,
                    confirmButtonColor: 'green',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then(result => {
                    if (result.value) {
                        console.log("id", id);
                        this.msg = '';
                        this.deleteEmployeeUser({id: id}).then(res => {
                            if (res.status === 200) {
                                this.msg = res.message ? res.message : "Employee deleted successfully"
                                this.$swal({
                                    icon: 'success',
                                    text: this.msg,
                                    toast: true,
                                    showCloseButton: false,
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                            }
                        }).catch(error => {
                            this.unauthorizedError(error)
                        });
                    }
                });

            },
            checkDocsClass(isDocs) {
                if (isDocs) return 'text-success'
                return 'text-danger'
            },
            checkDocs(item) {
                let notVerified = item.documents.filter((x) => {
                    return x.status !== 'Approved';
                });
                let verified = item.documents.filter((x) => {
                    return x.status === 'Approved';
                });

                if (item.is_docs) return 'Verified | ' + verified.length
                return 'Not Verified | ' + notVerified.length + "!"
            }
        },
        mounted() {
            this.getEmployees();
        },
        created() {
        }
    };
</script>
